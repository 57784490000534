@font-face {
  font-family: "Relative";
  font-style: normal;
  font-weight: 400;
  src: url("./assert/fonts/relative/relative-book-pro.eot"); /* IE9 Compat Modes */
  src: local(""), url("./assert/fonts/relative/relative-book-pro.eot?#iefix") format("embedded-opentype"),
    /* IE6-IE8 */ url("./assert/fonts/relative/relative-book-pro.woff2") format("woff2"),
    /* Super Modern Browsers */ url("./assert/fonts/relative/relative-book-pro.woff") format("woff"),
    /* Modern Browsers */ url("./assert/fonts/relative/relative-book-pro.ttf") format("truetype"); /* Safari, Android, iOS */
}

/* for certain components like inputs, don't overwrite the number font */
@font-face {
  font-family: "RelativeNumber";
  font-style: normal;
  font-weight: 400;
  src: url("./assert/fonts/relative/relative-book-pro.eot"); /* IE9 Compat Modes */
  src: local(""), url("./assert/fonts/relative/relative-book-pro.eot?#iefix") format("embedded-opentype"),
    /* IE6-IE8 */ url("./assert/fonts/relative/relative-book-pro.woff2") format("woff2"),
    /* Super Modern Browsers */ url("./assert/fonts/relative/relative-book-pro.woff") format("woff"),
    /* Modern Browsers */ url("./assert/fonts/relative/relative-book-pro.ttf") format("truetype"); /* Safari, Android, iOS */
}

@font-face {
  font-family: "Montserrat-SemiBold";
  font-style: normal;
  font-weight: 400;
  src: url("./assert/fonts/Montserrat-SemiBold.otf"); /* Safari, Android, iOS */
}

@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  src: url("./assert/fonts/Montserrat-Regular.otf"); /* Safari, Android, iOS */
}

@font-face {
  font-family: "Montserrat-Regular";
  font-style: normal;
  font-weight: 400;
  src: url("./assert/fonts/Montserrat-Regular.otf"); /* Safari, Android, iOS */
}

@font-face {
  font-family: "Montserrat-Light";
  font-style: normal;
  font-weight: 400;
  src: url("./assert/fonts/Montserrat-Light.otf"); /* Safari, Android, iOS */
}
