body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #18181A;
}
body, html, #root {
  width: 100%;
  height: 100%;
  position: relative;
}
a {
  text-decoration: none;
  color: #939393;
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
  -webkit-user-select: none;
  -moz-user-focus: none;
  -moz-user-select: none;
}
a,a:hover,a:active,a:visited,a:link,a:focus{
  -webkit-tap-highlight-color:rgba(0,0,0,0);
  -webkit-tap-highlight-color: transparent;
  outline:none;
  background: none;
  text-decoration: none;
}
* {
  &::selection {
    background: #FFF;
    color: #333;
  }
  &::-moz-selection {
    background: #FFF;
    color: #333;
  }
  &::-webkit-selection {
    background: #FFF;
    color: #333;
  }
}
input {
  -webkit-tap-highlight-color:rgba(0,0,0,0);
}
* {
  margin: 0;
  padding: 0;
}
ul, li, ol{
  list-style:none;

}
html, body{
  width:100%;
}
* {
  margin:0;padding:0;box-sizing: border-box;
}
.fp-table {
  display: block!important;
}

.fp-overflow,#fullpage{
  width:100%;
  height:100%;
}
.fp-watermark,.fp-warning {
    display: none!important;
    z-index: -9999999!important;
    position: relative!important;
    opacity: 0!important;
    visibility: hidden!important;
    transform: translateZ(-99999px);
}
* {
  .fp-watermark,.fp-warning {
    display: none!important;
      z-index: -9999999!important;
      position: relative!important;
      opacity: 0!important;
      visibility: hidden!important;
      transform: translateZ(-99999px);
  }
}