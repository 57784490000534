@import "styles/menu";

button {
  border:none;
  cursor: pointer;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes arrow
{
  0% {transform: translateY(-10px);}
  50% {transform: translateY(0);}
  100% {transform: translateY(-10px);}
}