
.menu-toggle {
  position: relative;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  padding: 6px;
  background-color: transparent;
  border-color: transparent;
  outline: none;
  transform: translateZ(0);
  transition: transform 0.1s ease-out;
  &:active {
    transform: translateY(4px);
  }
  &:focus .menu-line:after {
    background-color: black;
  }
  &.open{
    .menu-line{
      &:nth-child(1) {
        -webkit-animation: menu-jump-1 0.9s forwards ease;
        animation: menu-jump-1 0.9s forwards ease;
        &:after {
          -webkit-animation: menu-line-1 0.9s forwards ease-in-out;
          animation: menu-line-1 0.9s forwards ease-in-out;
        }
      }
      &:nth-child(2) {
        -webkit-animation: menu-jump-2 0.9s forwards ease;
        animation: menu-jump-2 0.9s forwards ease;
        &:after {
          -webkit-animation: menu-line-2 0.9s forwards ease-in-out;
          animation: menu-line-2 0.9s forwards ease-in-out;
        }
      }
      &.menu-line:nth-child(3) {
        -webkit-animation: menu-jump-3 0.9s forwards ease-out;
        animation: menu-jump-3 0.9s forwards ease-out;
      }
    }
  }
  &.close {
    .menu-line{
      &:nth-child(1) {
        animation: menu-jump-1 0.9s reverse ease;
        &:after {
          animation: menu-line-1 0.9s reverse ease-in-out;
        }
      }
      &:nth-child(2) {
        animation: menu-jump-2 0.9s reverse ease;
        &:after {
          animation: menu-line-2 0.9s reverse ease-in-out;
        }
      }
      &.menu-line:nth-child(3) {
        -webkit-animation: menu-jump-3 0.9s forwards ease-out;
        animation: menu-jump-3 0.9s forwards ease-out;
      }
    }
  }
}
.menu-line {
  display: block;
  width: 20px;
  padding: 1.5px;
  &:after {
    content: "";
    display: block;
    width: 100%;
    height: 1px;
    background-color: #fff;
    border-radius: 1px;
    transform: translateZ(0) rotate(0);
    transition: background-color 0.2s ease-out;
  }
}

@keyframes menu-line-1 {
  10% {
    transform: translateZ(0) rotate(0);
  }
  80% {
    transform: translateZ(0) rotate(395deg);
  }
  90%, 100% {
    transform: translateZ(0) rotate(404deg);
  }
}
@keyframes menu-line-2 {
  10% {
    transform: translateZ(0) rotate(0);
  }
  20% {
    transform: translateZ(0) rotate(10deg);
  }
  90%, 100% {
    transform: translateZ(0) rotate(-404deg);
  }
}
@keyframes menu-jump-1 {
  10% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-50px);
  }
  90%, 100% {
    transform: translateY(-6px);
  }
}
@keyframes menu-jump-2 {
  10% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-25px);
  }
  85%, 100% {
    transform: translateY(-10px);
  }
}
@keyframes menu-jump-3 {
  10% {
    transform: translateY(-7.5px) rotate(15deg);
  }
  30% {
    transform: translateY(-20px) rotate(-10deg);
  }
  50% {
    transform: translateY(7.5px) rotate(5deg);
  }
  80% {
    transform: translateY(0);
  }
}
@keyframes glow {
  50% {
    box-shadow: rgba(131, 131, 131, 0.4) 0 0 2px 2px;
  }
}
